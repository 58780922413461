import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"
import moment from "moment"

const IndexPage = ({ data }) => {
  const [videoList, setVideoList] = React.useState(
    data.allContentfulVideoEntry.edges
  )
  const updateVideoList = filterString => {
    setVideoList(
      data.allContentfulVideoEntry.edges.filter(video =>
        video.node.title.toLowerCase().includes(filterString.toLowerCase())
      )
    )
  }
  return (
    <Layout>
      <SEO title="Live Stream" />
      <label>
        Search:
        <input type="text" onChange={e => updateVideoList(e.target.value)} />
      </label>
      <br />
      <br />
      {videoList
        ? videoList.map((video, index) => (
            <div key={index}>
              <h2>{video.node.title}</h2>
              <h3>
                Aired {moment(video.node.serviceStartTime).fromNow()} on{" "}
                {moment(video.node.serviceStartTime).format("dddd, MMMM Do")}
              </h3>
              {video.node.videoUrl ? (
                <div>
                  {video.node.displayLinkAs ? (
                    <Video
                      videoSrcURL={video.node.videoUrl}
                      videoTitle={video.node.title}
                    />
                  ) : (
                    <button
                      className="LinkButton"
                      onClick={() =>
                        (window.location.href = video.node.videoUrl)
                      }
                    >
                      Click Here to Watch
                    </button>
                  )}
                </div>
              ) : (
                <p>Link coming, check back soon.</p>
              )}
              <hr />
            </div>
          ))
        : "No results match your search criteria."}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query MyQuery {
    allContentfulVideoEntry(sort: { fields: serviceStartTime, order: DESC }) {
      edges {
        node {
          videoUrl
          title
          serviceStartTime
          displayLinkAs
        }
      }
    }
  }
`
